.container {
  @media (min-width: $mantine-breakpoint-sm) {
    height: 400px;
  }
}

.child {
  width: 100%;
  min-height: 220px;
  position: relative;

  @media (min-width: $mantine-breakpoint-sm) {
    flex: 1;
    width: 50%;

    position: relative;
  }
}

.image {
  max-height: 100%;
  max-width: 100%;
  width: auto;

  border: 40px solid
    color-mix(in srgb, var(--mantine-color-gray-1) 80%, transparent);
  border-radius: 24px;
}
.wordContainer {
  position: relative;
}
.animatedTitle {
  display: flex;
  flex-wrap: wrap;
}

.animatedTitle span {
  display: inline-block;
  padding: 5px;
  border-radius: 3px;
  z-index: 2;
  position: relative;
  transition: color 150ms ease;
}

.animatedTitle .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--mantine-color-primary-5);
  border-radius: 8px;
  transition:
    opacity 150ms ease,
    transform 150ms ease;
}

.animatedTitle .visibleBackground {
  opacity: 1;
  transform: scale(1);
}

.animatedTitle .hiddenBackground {
  opacity: 0;
  transform: scale(0.5);
}
